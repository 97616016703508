import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import navigationBarPhoto from "../../../../assets/images/navBar.png";
import Container from "../../../../bootstrap/Container/";
import SinglePhoto from "../../SinglePhoto/";
import TrackOrder from "../../TrackOrder";
import {
  HeaderBottomWrapper,
  Logo,
  LogoLink,
  NavigationBar,
  NavigationBarIcon,
  NavigationUl,
  NavigationWithOutRes,
  NavigationWrapper,
  NavLink,
  SingleNavItem,
  Wrapper,
} from "./HeaderBottom.styles.js";

const HeaderBottom = ({ userProfileInfo }) => {
  const { webSettingData, languageData } = useSelector(
    (state) => state.busLists
  );
  const [showNavItem, setShowNavItem] = useState(false);
  const [token, setToken] = useState(null);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, []);

  return (
    <Wrapper bg={webSettingData?.headercolor}>
      <Container>
        <HeaderBottomWrapper>
          <Logo>
            <LogoLink to="/">
              <SinglePhoto
                img={webSettingData?.headerlogo}
                alt="logo"
                style={{ height: "50px" }}
              />
            </LogoLink>
            <NavigationBar>
              <NavigationBarIcon
                src={navigationBarPhoto}
                alt="navigationIcon"
                onClick={() => setShowNavItem((prevState) => !prevState)}
              />
            </NavigationBar>
          </Logo>
          <NavigationWrapper>
            <NavigationWithOutRes>
            

              {token ? (
                <SingleNavItem>
                </SingleNavItem>
              ) : (
                <SingleNavItem>
                <a 
                  href="https://booking.easygoo.com/backend" 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  {
                    languageData?.navigation_login_button[
                      webSettingData?.language
                    ]
                  }
                </a>
              </SingleNavItem>
              
              )}
            </NavigationWithOutRes>

            {showNavItem && (
              <NavigationUl>
                

                {token ? (
                  <SingleNavItem>
                
                  </SingleNavItem>
                ) : (
                  <SingleNavItem>
                  <a 
                    href="https://booking.easygoo.com/backend" 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    {
                      languageData?.navigation_login_button[
                        webSettingData?.language
                      ]
                    }
                  </a>
                </SingleNavItem>
                  
                )}
              </NavigationUl>
            )}
          </NavigationWrapper>
        </HeaderBottomWrapper>
      </Container>
    </Wrapper>
  );
};

export default HeaderBottom;
