import React from "react";
import FooterBottom from "./FooterBottom";
import FooterTop from "./FooterTop";

function Footer() {
  return (
    <>
      <FooterBottom />
    </>
  );
}

export default Footer;
